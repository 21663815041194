import React from "react";
import Version from "@components/version";
import Phrases from "@helpers/Phrases";
import AnalyticsScripts from "@components/common/AnalyticsScripts";
import { Box, Container, Grid } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  FooterMain,
  FooterContainer,
  FooterContainerWrapper,
  FooterContent,
  FooterLogo,
  ClientLogos,
  FooterClientLogosWrapper,
} from "./Footer.styles";

const Footer: React.FC = () => {
  return (
    <FooterMain>
      <FooterClientLogosWrapper>
        <Container>
          <h3>Top teams build their product with TestTheTest</h3>
          <ClientLogos>
            <img src="/assets/images/footer-logos.svg" alt="logos" />
          </ClientLogos>
        </Container>
      </FooterClientLogosWrapper>
      <FooterContainerWrapper>
        <FooterContainer>
          <FooterContent>
            <Grid container>
              <Grid item xs={6}>
                <div>
                  <FooterLogo href="/">
                    <img
                      src="/assets/images/logo-horizontal.svg"
                      alt={Phrases.HTML_TITLE}
                    />
                  </FooterLogo>
                </div>
                <br />
                <br />
                <br />
                <br />
                <strong>Email:</strong>
                <> office&#64;testthetest.com</>
                <br />
                <strong>Phone:</strong>
                <> +1 (201) 472-5592</>
                <br />
                <br />
                <p>
                  &copy; {new Date().getFullYear()} Bunt Inc. All rights
                  reserved.
                  <br />
                  <small>
                    <Version />
                  </small>
                </p>
                <br />
                <div>
                  <a
                    href="https://www.x.com/theTestTheTest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                  &nbsp;
                  <a
                    href="https://www.linkedin.com/company/testthetest/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <strong>&nbsp;</strong>
                  <br />
                  <br />
                  <strong>In New York:</strong>
                  <br />
                  10th Floor, Spaces Midtown South
                  <br />
                  401 Park Avenue
                  <br />
                  New York, NY 10016
                  <br />
                  United States
                  <br />
                  <br />
                  <strong>In London:</strong>
                  <br />
                  86-90 Paul Street
                  <br />
                  London, EC2A 4NE
                  <br />
                  United Kingdom
                  <br />
                  <br />
                  <a href="https://www.buntlabs.com">www.buntlabs.com</a>
                  <br />
                  <br />
                </Box>
              </Grid>
            </Grid>
          </FooterContent>
        </FooterContainer>
      </FooterContainerWrapper>
      <AnalyticsScripts />
    </FooterMain>
  );
};

export default Footer;
