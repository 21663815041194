import styled from "styled-components";

export const ConsentContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: rgba(25, 25, 25, 0.9);
  color: #efefef;
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  z-index: 9999999;
`;

export const ConsentWrapper = styled.div`
  text-align: left;
`;

export const ConsentHeader = styled.h1`
  color: white;
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  padding: 0;
`;

export const ConsentButton = styled.a`
  display: inline-block;
  font-size: 0.9rem;
  font-weight: bold;
  color: #f6c521 !important;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #f6c521;
  border-radius: 3px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
