import cookies from "js-cookie";
import ReactGA from "react-ga4";
import * as momentTZ from "moment-timezone";

const TRACKING_ID = "G-FDCWXZJ9JF";

const COOKIE_CONSENT_WAS_GIVEN = "COOKIE_CONSENT_WAS_GIVEN";

let isGaInitialized = false;

const isConsentRequired = (timeZoneName: string) => {
  // Err on the side of caution: everything in Europe gets the consent dialog.
  if (timeZoneName && timeZoneName.indexOf("Europe/") >= 0) {
    return true;
  }
  switch (timeZoneName) {
    case "Asia/Famagusta":
      return true;
    // case "America/New_York":
    //   return true;
    case "Asia/Nicosia":
      return true;
    case "Atlantic/Azores":
      return true;
    case "Atlantic/Madeira":
      return true;
    case "Africa/Ceuta":
      return true;
    case "Atlantic/Canary":
      return true;
    default:
      return false;
  }
};

export const shouldAskForConsent = () => {
  return (
    isConsentRequired(Intl.DateTimeFormat().resolvedOptions().timeZone) ||
    isConsentRequired(momentTZ.tz.guess())
  );
};

export const consentWasAlreadyGiven = () => {
  return !!cookies.get(COOKIE_CONSENT_WAS_GIVEN);
};

export const gaInitializeIfNeeded = () => {
  if (isGaInitialized) {
    return;
  }
  isGaInitialized = true;
  ReactGA.initialize(TRACKING_ID);
};

/**
 * Tracks a pageview but only if the user is outside GPDR, or, if
 * they have consented already.
 */
export const gaTrackPageview = () => {
  if (shouldAskForConsent() && !consentWasAlreadyGiven()) {
    return;
  }
  gaInitializeIfNeeded();
  ReactGA.send("pageview");
};

export const setConsentCookie = () => {
  cookies.set(COOKIE_CONSENT_WAS_GIVEN, "YES", { expires: 14 });
};

/**
 * Tracks a GA event, but only if the user is outside GPDR, or, if
 * they have consented already.
 */
export const gaTrackEvent = (
  category: string,
  action: string,
  label: string,
) => {
  if (shouldAskForConsent() && !consentWasAlreadyGiven()) {
    return;
  }
  console.log([category, action, label]);
  gaInitializeIfNeeded();

  ReactGA.event({
    category,
    action,
    label,
    // value: 1,
    // nonInteraction: true,
    transport: "xhr",
  });
};
