import { Card, Typography } from "@mui/material";
import styled from "styled-components";

export const SpeechBubble = styled(Card)`
  background-color: transparent;
  border-radius: 0.75rem;
  position: relative;
  color: #fff;
  padding-left: 0.75rem;
`;

export const QuoteMark = styled(Typography)`
  font-size: 5rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  opacity: 0.1;
  color: white;
`;

export const TestimonialWrapper = styled.div`
  padding: 0.25rem;
  height: 100%;
  position: relative;
`;

export const Body = styled(Typography)`
  font-size: 1rem;
  font-style: italic;
  padding-bottom: 0.75rem;
`;

export const Name = styled(Typography)`
  color: #fff;
  font-weight: 700;
  opacity: 0.8;
`;

export const Subtitle = styled(Typography)`
  color: #fff;
  font-weight: 900;
`;
