import styled from "styled-components";

export const HeroContainer = styled.div`
  position: relative;
  padding: 0;

  & > IMG {
    width: 100%;

    @media (max-width: 767px) {
      width: 20rem;
    }
  }
`;
