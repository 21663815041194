import React, { useCallback, useContext } from "react";
import { AuthContext } from "@storage/AuthProvider";
import Router from "next/router";
import { Box, Container, Stack } from "@mui/material";
import {
  NavbarContainer,
  NavbarLink,
  PortraitLogoContainer,
} from "./FrontPageHeader.styles";

interface IProps {
  handleRequestDemoShow: (e: any) => void;
}

const FrontPageHeader: React.FC<IProps> = ({ handleRequestDemoShow }) => {
  const { user, clearAccessToken } = useContext(AuthContext);

  const toggleAuthState = useCallback(
    (e: any) => {
      e.preventDefault();
      if (user?.companyRole) {
        clearAccessToken();
      }
      Router.push("/login");
    },
    [user?.companyRole, clearAccessToken],
  );

  return (
    <Container>
      <Stack direction="row">
        <Box flex={1}>
          <a className="navbar-brand" href="/">
            <PortraitLogoContainer>
              <img
                src="/assets/images/logo.svg"
                alt="Autonomous QA for web, mobile and desktop apps"
              />
            </PortraitLogoContainer>
          </a>
        </Box>
        <Box
          sx={{
            paddingTop: 8,
            "@media (max-width: 1040px)": { paddingTop: 0 },
          }}
        >
          <NavbarContainer>
            <NavbarLink active={false}>
              <a href="https://blog.testthetest.com/">Blog</a>
            </NavbarLink>
            <NavbarLink active={false}>
              <a href="#pricing">Pricing</a>
            </NavbarLink>
            {user?.companyRole == null && (
              <NavbarLink active>
                <a href="#" onClick={handleRequestDemoShow}>
                  Get Started Today
                </a>
              </NavbarLink>
            )}
            {user?.companyRole && (
              <NavbarLink active={false}>
                <a onClick={toggleAuthState} href="/">
                  {user?.companyRole ? "Logout" : "Login"}
                </a>
              </NavbarLink>
            )}
          </NavbarContainer>
        </Box>
      </Stack>
    </Container>
  );
};

export default FrontPageHeader;
