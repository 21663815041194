import styled from "styled-components";

export const FooterLogo = styled.a`
  padding: 1.25rem 2.05rem;
  width: 9.125rem;
  background: #4048ff;
  border-radius: 2.45rem;
  margin-bottom: 0.15rem;
  text-align: center;

  & IMG {
    opacity: 0.85;
    width: 4.5rem;
    margin: 0 auto;
  }

  & IMG:hover {
    opacity: 1;
  }
`;

export const FooterContainerWrapper = styled.div`
  margin: 0;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #243050;
`;

export const FooterContent = styled.div`
  padding: 5.25rem 1.5rem;
  margin: 0 auto;

  small {
    opacity: 0.5;
  }

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

export const FooterMain = styled.div`
  margin-top: 3.25rem;
  padding-top: 3.25rem;
  color: #939eb8;

  & A {
    color: #939eb8;
  }
  & A:hover {
    color: #fff;
  }

  & H3 {
    color: #243050;
    font-size: 1.25rem;
    margin: 0 0 0.95rem -0.2rem;
    padding: 0;
  }
`;

export const FooterClientLogosWrapper = styled.div`
  background-image: url("/assets/images/footer-bg.png");
  background-size: cover;
  padding: 2.25rem 0 2.25rem 0;
`;

export const ClientLogos = styled.div`
  & IMG {
    opacity: 0.75;
    width: 103%;
    margin: 0 -1.25rem 0 -1.25rem;
  }
`;
