import React from "react";
import { HeroContainer } from "./AnimatedHero.styles";

const AnimatedHero: React.FC = () => {
  return (
    <HeroContainer>
      <img src="/assets/images/tigephant-min.png" alt="hero" />
    </HeroContainer>
  );
};

export default AnimatedHero;
