import React from "react";
import FrontPageHeader from "@components/page/header/FrontPageHeader";
import AnimatedHero from "@components/page/AnimatedHero";
import Footer from "@components/page/footer/Footer";
import PrettyButton from "@components/common/PrettyButton";
import {
  BigCrossSection,
  BrowserButton1,
  BrowserButton2,
  BrowserButton3,
  BrowserUrl,
  Headline1,
  JumboBannerImage,
  JumboContainer,
  JumboContent,
  JumboDividerHero,
  JumboDividerHeroTextContainer,
  JumboHeadline1,
  NarrowSectionContainer,
  PageDividerHero,
  PageDividerHeroTextContainer,
  ScreenshotDemoIllustration,
  ThreeSomeContainer,
  ThreeSomeImageIcon,
} from "@components/page/FrontPage.styles";
import { Box, Container, Grid } from "@mui/material";
import { FooterContainer } from "@helpers/style/global.styles";
import { gaTrackEvent } from "@helpers/AnalyticsHelper";
import { useRouter } from "next/navigation";
import PackageList from "@components/subscription/PackageList";
import Testimonial from "@components/page/Testimonial";

interface Props {}

const Home: React.FC<Props> = () => {
  const router = useRouter();

  const handleRequestDemoShow = () => {
    gaTrackEvent("ACTION", "CTA_CLICK", "Get Started Today");
    router.push("/account/signup");
  };

  return (
    <main role="main">
      <JumboContainer>
        <FrontPageHeader handleRequestDemoShow={handleRequestDemoShow} />
        <Container>
          <JumboContent>
            <Grid container>
              <Grid item sm={12} md={6} sx={{ paddingRight: 5 }}>
                <JumboHeadline1>
                  Bugs happen where you least expect them
                </JumboHeadline1>
                <Box sx={{ marginBottom: 3 }}>
                  Catch regressions with our flexible plans! It&apos;s the best
                  of both worlds: <strong>Accurate</strong> as manual testing
                  should be. <strong>Cheap and fast</strong> like automations
                  are.
                </Box>
                <PrettyButton
                  onClick={handleRequestDemoShow}
                  title="Get your first report"
                />
                <br />
                <br />
                &nbsp;
              </Grid>
              <Grid item sm={12} md={6}>
                <JumboBannerImage>
                  <AnimatedHero />
                </JumboBannerImage>
              </Grid>
            </Grid>
          </JumboContent>
        </Container>
      </JumboContainer>

      <JumboDividerHero>
        <JumboDividerHeroTextContainer>
          <h1>
            The goal is <span>quality</span>, not <span>testing</span>
          </h1>
          <h2>
            <span>&quot;Testing&quot;</span> means different things to different
            people:
          </h2>
          <div>
            <ul>
              <li>
                Test-driven development largely uses <strong>unit tests</strong>
                , verifying parts of the code in isolation
              </li>
              <li>
                <strong>Snapshot tools</strong> catch regressions in the way app
                components are rendered in a browser
              </li>
              <li>
                Quality assurance teams run <strong>end-to-end tests</strong> to
                simulate real users&apos; actions
              </li>
              <li>
                <strong>Exploratory testing</strong> and beta builds may uncover
                issues that scripted tests missed
              </li>
              <li>
                Active <strong>monitoring</strong> sends alerts when real users
                of the website encounter errors
              </li>
            </ul>
          </div>
          <h5>The problem we&apos;re solving</h5>
          <div>
            At TestTheTest, we believe that dev teams <em>should</em> write code
            test-driven, run automated tests on CI. The best testing is using
            the app like users do, and make sure all features are tested
            end-to-end.
          </div>
          <div>
            But running an entire end-to-end suite across a complex product
            comes at a high price. A detailed suite may take literally hours to
            run, slowing down high-output teams. And a short test suite simply
            doesn&apos;t test enough of the application.
          </div>
          <div>
            Constant maintenance also eats into developer budget, takes away
            project management time, and with shifting priorities, the best
            intentioned dev teams often end up with a suite that doesn&apos;t
            help them catch bugs.
          </div>
          <h5>The way we&apos;re solving it</h5>
          <div>
            TestTheTest is the autonomous, reliable testing solution that
            ensures that every last corner of your application is tested
            regularly. You can use it for baseline testing and stay entirely
            hands off. Or you can be involved: edit the test suite, run
            automated suites on CI and act on the alerts immediately.
          </div>
          <div>
            <strong>Included in all our packages:</strong>
          </div>
          <div>
            <ul>
              <li>
                Automated <strong>feature discovery</strong>; we write and
                maintain your testing book
              </li>
              <li>
                Run <strong>complete end-to-end test runs</strong>, where our
                team tests every single test case, at least weekly
              </li>
              <li>
                Automate a portion of the end-to-end runs, to run as{" "}
                <strong>active monitoring &mdash; on your CI or ours</strong>
              </li>
            </ul>
          </div>
        </JumboDividerHeroTextContainer>
      </JumboDividerHero>

      <ThreeSomeContainer id="process">
        <Container>
          <Grid container>
            <Grid item md={12} lg={4} marginX="auto">
              <ThreeSomeImageIcon icon="wrench" />
              <Headline1>Autonomous testing</Headline1>
              <div>
                Once we can access your app, you&apos;ll have your reports
                within the week. No code access, no onboarding, no integration
                or &quot;quick meeting&quot; &mdash; just your results.
              </div>
            </Grid>
            <Grid item md={12} lg={4} marginX="auto">
              <ThreeSomeImageIcon icon="cool" />
              <Headline1>The human touch</Headline1>
              <div>
                TestTheTest is the best of both worlds. Accurate as manual
                testing should be. Cheap and robust like automations are.
              </div>
            </Grid>
            <Grid item md={12} lg={4} marginX="auto">
              <ThreeSomeImageIcon icon="what" />
              <Headline1>Clear reports</Headline1>
              <div>
                Screenshots, videos, error logs or step-by-step instructions
                will help you replicate bugs. Enterprise customers can connect
                to us on Slack and get unlimited ad-hoc tests.
              </div>
            </Grid>
          </Grid>
        </Container>
      </ThreeSomeContainer>

      <BigCrossSection>
        <Container
          sx={{
            marginTop: 7,
            height: "25rem",
            width: "58rem",
            position: "relative",
          }}
        >
          <ScreenshotDemoIllustration
            sx={{
              top: "1.5rem",
              left: 0,
              backgroundImage: "url(/assets/images/screenshot-1.jpg)",
            }}
          >
            <BrowserButton1 />
            <BrowserButton2 />
            <BrowserButton3 />
            <BrowserUrl />
          </ScreenshotDemoIllustration>
          <ScreenshotDemoIllustration
            sx={{
              top: "2.5rem",
              left: "27rem",
              backgroundImage: "url(/assets/images/screenshot-3.jpg)",
            }}
          >
            <BrowserButton1 />
            <BrowserButton2 />
            <BrowserButton3 />
            <BrowserUrl />
          </ScreenshotDemoIllustration>
          <ScreenshotDemoIllustration
            sx={{
              top: 0,
              left: "13.5rem",
              backgroundImage: "url(/assets/images/screenshot-2.jpg)",
            }}
          >
            <BrowserButton1 />
            <BrowserButton2 />
            <BrowserButton3 />
            <BrowserUrl />
          </ScreenshotDemoIllustration>
        </Container>
        <Container sx={{ textAlign: "center" }}>
          <NarrowSectionContainer>
            <Headline1>100% Test case coverage. Weekly.</Headline1>
            <div>
              Regular testing helps find bugs where &quot;the code hasn&#39;t
              changed&quot;. We caught bugs caused by simple Node and Python
              package updates &mdash; because who else would think to test the
              forgotten password flow <em>again</em>?
            </div>
          </NarrowSectionContainer>
        </Container>
      </BigCrossSection>

      <Container>
        <Box sx={{ textAlign: "center", marginBottom: 5 }}>
          <PrettyButton
            onClick={handleRequestDemoShow}
            title="Get your first report"
          />
        </Box>
      </Container>

      <br />
      <br />

      <PageDividerHero>
        <PageDividerHeroTextContainer>
          <Grid container spacing={4}>
            {[
              {
                text: (
                  <>
                    TestTheTest has been a real lifesaver, especially with all
                    the chaos of these startup days when we&apos;re juggling a
                    million things at once. It&apos;s helped us catch issues we
                    didn&apos;t even think of and let us keep our heads down on
                    product work.
                  </>
                ),
                name: "Mark Nunes",
                position: "CEO",
                company: <>That&apos;s the One</>,
              },
              {
                text: (
                  <>
                    TestTheTest has proven itself to be very useful for our
                    development process and improving our user experience. On
                    the first run, their system identified 2 critical bugs that
                    went undetected by our team of testers, and we were able to
                    quickly patch those up thanks to their detailed reports.
                  </>
                ),
                name: "Brian Howard",
                position: "Founder",
                company: "BarSight",
              },
              {
                text: (
                  <>
                    Weekly end-to-end testing is invaluable for us, because our
                    customers value reliability and high availability.
                    TestTheTest kept track of all issues while we moved our
                    legacy PHP service to a more modern Nextjs stack. We reached
                    Bug Zero with their help, and maintaining it ever since.
                  </>
                ),
                name: "John Derham",
                position: "Product owner",
                company: "Short-Codes",
              },
            ].map((testimonial) => (
              <Grid item xs={12} sm={6} md={4} key={testimonial.name}>
                <Testimonial {...testimonial} />
              </Grid>
            ))}
          </Grid>
        </PageDividerHeroTextContainer>
      </PageDividerHero>

      <br />

      <Container id="pricing">
        <Headline1 style={{ textAlign: "center" }}>Pricing</Headline1>
        <PackageList includeHome />
      </Container>

      <Container>
        <Box sx={{ textAlign: "center", marginBottom: 5 }}>
          <PrettyButton
            onClick={handleRequestDemoShow}
            title="Get your first report"
          />
        </Box>
      </Container>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </main>
  );
};

export default Home;
