import React, { ReactNode } from "react";
import { CardContent } from "@mui/material";
import {
  QuoteMark,
  SpeechBubble,
  TestimonialWrapper,
  Body,
  Name,
  Subtitle,
} from "./Testimonial.styles";

type Props = {
  text: string | ReactNode;
  name: string;
  position: string;
  company: string | ReactNode;
};

const Testimonial = ({ text, name, position, company }: Props) => {
  return (
    <TestimonialWrapper>
      <SpeechBubble elevation={3}>
        <QuoteMark variant="h1">“</QuoteMark>
        <CardContent>
          <Body variant="body1">{text}</Body>
          <Name variant="subtitle1">{name}</Name>
          <Subtitle variant="subtitle2">
            {position}, {company}
          </Subtitle>
        </CardContent>
      </SpeechBubble>
    </TestimonialWrapper>
  );
};

export default Testimonial;
