import { Box, Container } from "@mui/material";
import styled from "styled-components";

export const Headline1 = styled.h1`
  color: #101f41;
  font-weight: 700;
  line-height: 1.95rem;
  margin-top: 2.1rem;
  font-size: 1.925rem;
  font-weight: 900;
`;

export const JumboHeadline1 = styled.h1`
  font-size: 60px;
  line-height: 1;
  font-weight: 900;
  margin-top: 2.1rem;
  margin-bottom: 30px;
  color: #243050;

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`;

export const ThreeSomeContainer = styled.div`
  margin: 5rem 0 4.5rem 0;
  text-align: center;
`;

export const ThreeSomeImageIcon = styled.div<{
  icon: "what" | "wrench" | "hello" | "cool";
}>`
  height: 11.5rem;
  width: 14.5rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;

  background-image: ${({ icon }) =>
    `url("/assets/images/icons/ic-${icon}.svg")`};

  @media (max-width: 767px) {
    margin: 3rem auto 0;
  }
`;

export const BigCrossSection = styled.div`
  margin: 0 0 1.35rem 0;
  padding: 0;
  overflow: hidden;
`;

export const ScreenshotDemoIllustration = styled(Box)`
  margin: 0;
  background-repeat: no-repeat;
  background-color: #e2e2e2;
  background-position: 0 1.5rem;
  background-size: 30rem 20.25rem;
  width: 30rem;
  height: 22.125rem;
  position: absolute;
  border: 0.1rem solid #f2f2f2;
  border-radius: 0.5rem;
  box-shadow: 0.15rem 0.15rem 0.75rem #00000005;
`;

export const BrowserButton1 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background: #ec6a5f;
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserButton2 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  background: #f5bf4f;
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserButton3 = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 2.5rem;
  background: #61c554;
  border-radius: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
`;

export const BrowserUrl = styled.div`
  position: absolute;
  top: 0.4rem;
  left: 7.5rem;
  background: #f2f2f2;
  border-radius: 0.25rem;
  width: 15rem;
  height: 0.8rem;
`;

export const JumboDividerHero = styled(Container)`
  margin-top: -2.45rem;
  background: linear-gradient(-45deg, #f0f6f9, #f0f6f9);
  border-radius: 1.95rem;
  padding: 0;
  z-index: 1;
  color: #243050;

  display: flex;
`;

export const JumboDividerHeroTextContainer = styled.div`
  flex: 7/12;
  padding: 0 4.5rem 1rem 1.5rem;

  & H1 {
    color: #4048ff;
    font-size: 1.65rem;
    line-height: 1.6rem;
    margin-bottom: 0.25rem;

    & SPAN {
      color: #4048ff;
      font-weight: 900;
      font-size: 1.95rem;
    }
  }

  & H2 {
    color: #243050;
    font-size: 1.35rem;
    line-height: 1.6rem;
    font-weight: 900;
    margin-top: 0.55rem;
  }

  & DIV {
    margin: 0.75rem 0;
    font-size: 1.05rem;
  }

  @media (max-width: 991px) {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }
`;

export const PageDividerHero = styled(Container)`
  margin-top: -2.45rem;
  background: linear-gradient(-45deg, #243050, #333daf);
  border-radius: 1.95rem;
  padding: 1.75rem;
  z-index: 1;
  color: #fff;
  display: flex;
`;

export const PageDividerHeroTextContainer = styled.div`
  padding: 0;
`;

export const NarrowSectionContainer = styled.div`
  text-align: center;
  max-width: 70%;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 95%;
  }
`;

export const JumboContainer = styled.div`
  padding: 1rem 0;
  background: linear-gradient(-45deg, #e17a7e, #fff5d3, #e17a7e);
  background-size: 400% 400%;
  animation: jumbogradient 35s ease infinite;
  animation-direction: alternate;
  font-size: 1.2rem;

  @keyframes jumbogradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media (max-width: 767px) {
    background: linear-gradient(-45deg, #e17a7e, #fff5d3, #e17a7e);
    background-size: 600% 400%;
    animation: jumbogradient 35s ease infinite;
  }

  @media (min-width: 767px) and (max-width: 1200px) {
    background: linear-gradient(-45deg, #e17a7e, #fff5d3, #e17a7e);
    background-size: 600% 400%;
    animation: jumbogradient 35s ease infinite;
  }
`;

export const JumboContent = styled.div`
  padding: 3rem 0;
`;

export const JumboBannerImage = styled.div`
  margin: 3rem 0 -1.55rem 0;

  @media (min-width: 767px) and (max-width: 1200px) {
    margin: 5rem 0 -1.55rem 0;
  }

  @media (max-width: 767px) {
    margin: 0 0 -1.55rem 1.5rem;
  }
`;
